<template>
  <b-container>
    <Breadcrumbs currentpage="Bevestiging van de reservatie" />
    <h1>Bevestiging van de reservatie</h1>
    <hr />
    <b-row>
      <b-col cold="12">
        <div>
          <h3>Bedankt voor je reservatie!</h3>
          <p>
            Wij hebben de gegevens en details van je reservatie goed ontvangen
            en gaan hier nu mee aan de slag.
          </p>
          <p>
            Heb je vragen over de bestelling? Neem dan contact op met
            <a mailto="uitleendienst@vlaamsbrabant.be"
              >uitleendienst@vlaamsbrabant.be</a
            >
          </p>
        </div>
        <div>
          <h3>Overzicht van je reservatie</h3>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs }
}
</script>
